import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Content, { HTMLContent } from "../../components/Content"

const MetiersPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const PostContent = HTMLContent || Content
  return (
    <Layout langKey={"fr"}>
      <SEO
        title="Nos métiers de l'ingénierie informatique"
        description="Nous réalisons vos projets informatiques sur-mesure dans l'intégralité du cahier des charges jusqu'à la livraison et la maintenance"
        keywords={[`Audit`, `Conseil`, `Analyse des besoins`, `Conception`, `Développement`, `Prototypage`, `Maintenance`, `Intégration`, `Test logiciel`, `Formation`]}
        jsonLd={[
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Métiers",
                item: "https://www.aprogsys.com/fr/metiers/",
              },
            ],
          },
        ]}
      />
      <section className="hero metiers-banner" style={{overflow: 'hidden'}}>
        <div className="container is-fluid hero-body" style={{position:'relative'}}>
          <div className="columns">
              <div className="column is-7">
                <div className="hero-title">
                  <div style={{position:'relative',zIndex: 2}}>
                    <h1 className="is-size-3-touch" ><span>Nos métiers</span></h1>
                    <p className="subtitle is-size-4-touch is-size-3-tablet is-size-2-desktop" style={{ marginTop: '1rem' }}><span>La réalisation d'un projet de <strong>transformation numérique</strong> est un challenge.</span></p>
                    <p className="subtitle is-size-4-touch is-size-3-tablet is-size-2-desktop"><span>Notre équipe vous guide et vous accompagne vers sa concrétisation.</span></p>
                  </div>
                  <div className="is-hidden-mobile">
                    <svg style={{width:'80%',height: 400, maxWidth:1000,position:'absolute',bottom:-30, right:-60, zIndex: 1}}>
                      <use xlinkHref="/img/metiers-sprite.svg#icon-montagne" />
                    </svg>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <section className="metiers-details">
        <div className="container">
          <div style={{paddingBottom: '50px'}}>
            <h2 className="is-size-4-touch has-text-primary">{edges[0].node.frontmatter.title}</h2>
            <PostContent content={edges[0].node.html} className={"has-text-justified metiers-details-text"} />
          </div>
          <div className="content metiers-details-structure">
            <div className="is-hidden-touch is-hidden-desktop-only complFond1"></div>
            <div className="is-hidden-touch is-hidden-desktop-only complFond2"></div>    
            <div className="metier1 has-text-weight-bold is-hidden-touch is-hidden-desktop-only">
              <p className="is-size-2-desktop">1. Analyse <br/>des besoins</p>
            </div>
            <div className="metier1 has-text-weight-bold is-hidden-widescreen">
              <h2 className="is-size-4-touch">1. Analyse des besoins</h2>
            </div>

            <div className="metier1-ssm1">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-audit" />
              </svg>
              <h3 className="metier1-details is-size-5-touch is-marginless">Audit et conseil</h3> 
              <p className="is-marginless"><strong>La construction complète de votre projet</strong></p>
              <ul>
                <li>Analyse des principaux besoins</li>
                <li>Etat des lieux de la situation</li>
                <li>Etude des possibilités (selon vos contraintes)</li>
                <li>Explication des solutions possibles et conseils</li>
              </ul>
            </div>
            <div className="metier1-ssm2">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-gestion" />
              </svg>
              <h3 className="metier1-details is-marginless">Gestion de projet</h3> 
              <p className="is-marginless"><strong>Attribution d'un interlocuteur privilégié (chef de projet)</strong></p>
              <ul>
                <li>Gestion de l'ensemble du projet</li>
                <li>Rédaction du cahier des charges fonctionnelles</li>
                <li>Proposition financière</li>
                <li>Engagement sur les délais et résolution des imprévus</li>
              </ul>
            </div>

            <div className="metier2 has-text-right-desktop has-text-weight-bold is-hidden-touch is-hidden-desktop-only">
              <p className="is-size-2-desktop">2. Concevoir <br/>et développer</p>
            </div>
            <div className="metier2 has-text-weight-bold is-hidden-widescreen">
              <h2 className="is-size-4-touch">2. Concevoir et développer</h2>
            </div>

            <div className="metier2-ssm1">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-dev" />
              </svg>
              <h3 className="metier2-details is-marginless">Développement de logiciel</h3> 
              <p className="is-marginless"><strong>Développer, concevoir et éditer : les mots d'ordre que nos ingénieurs maîtrisent à la perfection</strong></p>
              <ul>
                <li>Développement pour tous types de plateformes</li>
                <li>Langages de programmation variés</li>
                <li>Conception et réalisation du site Web</li>
              </ul>
            </div>
            
            <div className="metier2-ssm2">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-proto" />
              </svg>
              <h3 className="metier2-details is-marginless">Prototypage et électronique</h3> 
              <p className="is-marginless"><strong>Elaboration de cartes électroniques<br/> selon vos besoins</strong></p>
            </div>

            <div className="metier3 is-hidden-touch is-hidden-desktop-only has-text-weight-bold">
              <p className="is-size-2-desktop">3. Livraison et <br/>maintenance des solutions</p>
            </div>
            <div className="metier3 has-text-weight-bold is-hidden-widescreen">
              <h2 className="is-size-4-touch">3. Livraison et maintenance des solutions</h2>
            </div>

            <div className="metier3-ssm1">
              <svg style={{height:90}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-tests" />
              </svg>
              <h3 className="metier3-details is-marginless">Maintenance, intégration et tests</h3> 
              <p className="is-marginless"><strong>Une assistance personnalisée</strong><br/>
              Nous serons à vos côtés pour vous conseiller, vous guider et mettre en place votre solution matérielle et logiciel. 
              Une fois la solution opérationnelle, votre forfait de maintenance (après consultation) couvrira tous les dysfonctionnements de votre logiciel
              </p>
            </div>

            <div className="metier4 has-text-right-desktop is-hidden-touch is-hidden-desktop-only has-text-weight-bold">
              <p className="is-size-2-desktop">4. Assurer l'évolution <br/>des solutions et la formation</p>
            </div>
            <div className="metier4 has-text-weight-bold is-hidden-widescreen">
              <h2 className="is-size-4-touch">4. Assurer l'évolution des solutions et la formation</h2>
            </div>
            
            <div className="metier4-ssm1">
              <svg style={{height:92}}>
                <use xlinkHref="/img/metiers-sprite.svg#icon-formation" />
              </svg>
              <h3 className="metier4-details is-marginless">Formation et suivi</h3>
              <p className="is-marginless"><strong>Un service à votre écoute au long terme</strong><br/>
              Nos experts vous proposent des formations adaptées afin que vous puissiez exploiter aux mieux les fonctionnalités de vos logiciels.
              Cela s'inscrit dans le programme de suivi-client de la société Aprogsys, pour être au plus proche de vos problématiques sur toute la durée d'exploitation du produit.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

MetiersPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default MetiersPage

export const MetiersPageQuery = graphql`
query MetiersFrQuery {
  allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "metiers" }, langKey: { eq: "fr" } } }
  ) {
    totalCount
    edges {
      node {
        id
        html
        frontmatter {
          title
        }
      }
    }
  }
}
`